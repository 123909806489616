export const LS_AFFILIATE_TOKEN = "affiliate_token";

export const getAffiliateTokenLocalStorage = () => {
  if (typeof window === "undefined") {
    return undefined;
  }
  return sessionStorage.getItem(LS_AFFILIATE_TOKEN);
};
export const setAffiliateTokenLocalStorage = (affiliateToken) => {
  if (typeof window === "undefined") {
    return undefined;
  }
  sessionStorage.setItem(LS_AFFILIATE_TOKEN, affiliateToken);
};

/**
 * Try to get affiliate code from local storage, if LS not available try to
 * extract from URL
 *
 * @returns {null|string}
 */
export const getAffiliateToken = () => {
  try {
    return getAffiliateTokenLocalStorage();
  } catch (e) {
    // local storage not available -> try to get it from url
    return getAffiliateTokenFromUrl();
  }
};

/**
 * Tries to extract token information from URL
 *
 * @returns { string | null}
 */
export const getAffiliateTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);

  let affiliateToken = null;

  if (urlParams.has("affiliateToken")) {
    affiliateToken = urlParams.get("affiliateToken");
  }

  if (urlParams.has("a")) {
    affiliateToken = urlParams.get("a");
  }

  if (urlParams.has("key")) {
    affiliateToken = urlParams.get("key");
  }

  return affiliateToken;
};
