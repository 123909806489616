import orderSlice from './order-slice';

const { actions, reducer } = orderSlice;

const orderStore = {
  ...actions,
  reducer,
};

export default orderStore
