import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import Decimal from "decimal.js";
import { getAffiliateTokenLocalStorage } from "../../utils";
import {
  OROUTER_DEV_PRICE,
  OROUTER_PRO_ID,
  OROUTER_PRO_PRICE,
} from "../../constants";

export const ORDER_STEP_CLEAN = "CLEAN";
export const ORDER_STEP_PM = "PAYMENT_METHOD";
export const ORDER_STEP_SA = "SHIPPING_ADDRESS";
export const ORDER_STEP_DETH_PAY = "DIRECT_ETH_PAYMENT";
export const ORDER_STEP_WERT_PAY = "DIRECT_WERT_PAYMENT";
export const ORDER_STEP_BITPAY_PAY = "DIRECT_BITPAY_PAYMENT";
export const ORDER_STEP_RES_SUCCESS = "RESULT_SUCCESS";
export const ORDER_STEP_RES_CANCELLED = "RESULT_CANCELLED";
export const ORDER_STEP_RES_FAILED = "RESULT_FAILED";

export const PAYMENT_TYPE_WERT = "wert";
export const PAYMENT_TYPE_BITPAY = "bitpay";
export const PAYMENT_TYPE_DIRECT_ETH = "cs-crypto-gw_v1";

const calculateTotal = (price, count) =>
  new Decimal(price).mul(new Decimal(count)).toNumber();

const defaultAddress = {
  email: "",
  firstName: "",
  lastName: "",
  street: "",
  streetOpt: "",
  postal: "",
  city: "",
  region: "",
  country: "",
};

const defaultState = {
  id: null,
  productId: null,
  productPrice: 0,
  productCount: 0,
  total: 0,
  shippingAddress: { ...defaultAddress, note: "" },
  paymentType: null,
  currentStep: ORDER_STEP_CLEAN,
  directETHPayment: null,
  affiliateToken: null,
  oRouterProPrice: OROUTER_PRO_PRICE, // fetch this as a constant
  oRouterDevPrice: OROUTER_DEV_PRICE, // fetch this as a constant
};

const userInterfaceSlice = createSlice({
  name: "order",
  initialState: defaultState,
  reducers: {
    clearPurchaseProcess: () => defaultState,

    setEmail: (state, action) => {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          email: action.payload.email,
        },
      };
    },

    setProductAndCount: (state, action) => {
      const { productId, productCount } = action.payload;
      const productPrice =
        productId === OROUTER_PRO_ID
          ? state.oRouterProPrice
          : state.oRouterDevPrice;
      return {
        ...state,
        productId,
        productPrice,
        productCount,
        total: calculateTotal(productPrice, productCount),
        affiliateToken: getAffiliateTokenLocalStorage(),
        currentStep: ORDER_STEP_PM,
      };
    },

    setPaymentType: (state, action) => {
      return {
        ...state,
        paymentType: action.payload.type,
        currentStep: ORDER_STEP_SA,
      };
    },

    setShippingAddress: (state, action) => ({
      ...state,
      shippingAddress: action.payload.shippingAddress,
    }),

    setEthCustomPayment: (state, action) => {
      const { ethAmount, ethAddress } = action.payload;
      return {
        ...state,
        directETHPayment: {
          ethAmount,
          ethAddress,
        },
      };
    },
    setORouterPrices: (state, action) => {
      const { oRouterDevPrice, oRouterProPrice } = action.payload;
      return {
        ...state,
        oRouterDevPrice,
        oRouterProPrice,
      };
    },
    setOrderId: (state, action) => ({
      ...state,
      id: action.payload.id,
    }),

    setOrderStep: (state, action) => {
      const newState = { ...state };
      const { currentStep } = action.payload;

      // Reset state partially
      if (
        [
          ORDER_STEP_RES_SUCCESS,
          ORDER_STEP_RES_CANCELLED,
          ORDER_STEP_RES_FAILED,
        ].includes(currentStep)
      ) {
        newState.id = null;
        newState.productId = null;
        newState.shippingAddress = { ...defaultAddress, note: "" };
        newState.directETHPayment = null;
      }

      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.auth,
      };
    },
  },
});

export default userInterfaceSlice;
