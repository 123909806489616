import { createWrapper } from "next-redux-wrapper";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";

import orderStore from "./order";

const rootReducer = combineReducers({
  order: orderStore.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
  });
  store.__persistor = persistStore(store);
  return store;
};

export const wrapper = createWrapper(makeStore);
export const selectOrderState = (state) => state.order;
