import axios from "axios";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Create verbose error, not just error message
    return Promise.reject(error.data);
  },
);

export const getProducts = () =>
  axios.get(`${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/product`);

export const getORouterPurchaseAvailability = () =>
  axios.get(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/orouter-current`,
  );

export const createOrder = (orderData) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/orouter`,
    orderData,
  );
export const createWertOrder = (orderData) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/wert/orouter`,
    orderData,
  );
export const createPaymentRequest = (orderId, paymentRequestData) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/${orderId}/payment/orouter`,
    paymentRequestData,
  );
export const signTransaction = (userAddress, productId) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/sign-transaction`,
    { userAddress, productId },
  );

export const pollCustomGW = (orderId) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/polling/eth-gateway/${orderId}`,
  );

export const queryAddressReward = (address) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_WAITLIST_API_BASE_URL}/v1/order/query-address-reward`,
    { address },
  );
