import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useStore } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "styled-components";
import { appWithTranslation } from "next-i18next";
import CookieConsent from "react-cookie-consent";
import "./index.css";
import Layout from "../components/layout";
import { lightTheme } from "../styles/theme.config";
import nextI18NextConfig from "../next-i18next.config.js";
import "../styles/globals.css";
import {
  getAffiliateTokenFromUrl,
  setAffiliateTokenLocalStorage,
} from "../utils";
import { wrapper } from "../store";
import { PersistGate } from "redux-persist/integration/react";
import { getProducts } from "./api";
import { OROUTER_DEV_ID, OROUTER_PRO_ID } from "../constants";
import orderStore from "../store/order";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const store = useStore();
  const dispatch = useDispatch();

  // Fetch the oRouter prices
  useEffect(() => {
    (async () => {
      const result = (await getProducts()).data;
      // find the oRouter product by id
      const oRouterDev = result.data.find(
        (product) => product.id === OROUTER_DEV_ID,
      );
      const oRouterPro = result.data.find(
        (product) => product.id === OROUTER_PRO_ID,
      );

      if (oRouterDev && oRouterPro) {
        dispatch(
          orderStore.setORouterPrices({
            oRouterDevPrice: Number(oRouterDev.price),
            oRouterProPrice: Number(oRouterPro.price),
          }),
        );
      }
    })();
  }, [dispatch]);

  /**
   * Process custom params from URL
   */
  useEffect(() => {
    const affiliateToken = getAffiliateTokenFromUrl();

    if (affiliateToken) {
      try {
        setAffiliateTokenLocalStorage(affiliateToken);

        // remove token param from url
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has("affiliateToken")) {
          queryParams.delete("affiliateToken");
        }

        if (queryParams.has("a")) {
          queryParams.delete("a");
        }

        if (queryParams.has("key")) {
          queryParams.delete("key");
        }

        if (queryParams.has("fbclid")) {
          queryParams.delete("fbclid");
        }

        router.push(queryParams.toString()).then();
      } catch {
        console.warn("Local storage not available");
      }
    }
  }, [router]);

  return (
    <PersistGate loading={<h1>...</h1>} persistor={store.__persistor}>
      <ThemeProvider theme={lightTheme}>
        <Layout>
          <AnimatePresence
            exitBeforeEnter
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
          >
            <Component {...pageProps} />
            <CookieConsent
              // location="bottom"
              buttonText="I Accept"
              declineButtonText="I Decline"
              enableDeclineButton
              onDecline={() => console.log("Declined")}
              cookieName="cookie_consent"
              className="cookie-consent"
              buttonStyle={{
                color: "white",
                backgroundColor: "rgba(36,138,255,1)",
                borderRadius: 10,
                fontSize: 14,
                padding: 8,
                minWidth: 125,
              }}
              declineButtonStyle={{
                color: "rgba(36,138,255,1)",
                backgroundColor: "white",
                borderRadius: 10,
                border: "2px solid rgba(36,138,255,1)",
                marginRight: 0,
                fontSize: 14,
                padding: 8,
                minWidth: 125,
              }}
              expires={150}
              contentClasses="CookieInner"
            >
              By browsing this website, you are allowing cookies from
              third-party services to improve your browsing experience.{" "}
              <a
                className="link"
                href="https://docs.overline.network/docs/amcegr-terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more {">"}
              </a>
            </CookieConsent>
          </AnimatePresence>
        </Layout>
      </ThemeProvider>
    </PersistGate>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp, nextI18NextConfig));
